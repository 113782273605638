import { CartBaseController } from "./cart_base_controller";

export default class extends CartBaseController {
  static targets = ["quantity", "total", "addedQuantity", "subtotal"];
  static values = {
    variantId: Number,
    lineItemId: Number,
    total: String,
    subtotal: String,
    quantity: {
      type: Number,
      default: 1,
    },
  };

  updateQuantity(event) {
    this.quantityValue = event.target.value;
  }

  async changeQuantity(event) {
    const quantity = parseInt(event.target.value);

    if (quantity < 1) return;

    event.target.disabled = true;

    const orderToken = await this.tokenGetOrCreate();
    const line_item_id = this.lineItemIdValue;
    const include = "line_items";

    const response = await this.spree.cart.setQuantity(
      { orderToken },
      { line_item_id, quantity, include }
    );

    event.target.disabled = false;
    event.target.focus();

    if (response.isFail()) {
      this.handleFailure(response);
      return;
    }

    const cart = response.success();
    const lineItem = cart.included.find(x => x.id === this.lineItemIdValue.toString());
    const itemTotal = cart.data.attributes.item_total;

    this.subtotalValue = lineItem.attributes.subtotal;
    this.totalValue = lineItem.attributes.total;
    this.counterUpdate(cart.data.attributes.item_count);
    this.dispatchToWindow("updateItemTotal", { itemTotal });
    this.dispatchToWindow("updateLineItem", { cart });
  }

  totalValueChanged(value, previousValue) {
    if (!this.hasTotalTarget) return;

    this.totalTarget.innerText = this.localizeNumber(value);

    if (!this.hasSubtotalTarget) return;

    this.subtotalTarget.hidden = (value === this.subtotalValue);
    this.subtotalTarget.innerText = this.localizeNumber(this.subtotalValue);
  }

  async add(event, floatingAlert = true) {
    const orderToken = await this.tokenGetOrCreate();
    const variant_id = this.variantIdValue;
    const include = "line_items";
    const quantity = this.quantityValue;
    const response = await this.spree.cart.addItem(
      { orderToken },
      { variant_id, quantity, include }
    );

    if (response.isFail()) {
      this.handleFailure(response);
      return;
    }

    const cart = response.success();
    const itemTotal = cart.data.attributes.item_total;
    const cartCounter = cart.data.attributes.item_count;

    this.dispatchToWindow("updateItemTotal", { itemTotal });
    this.dispatchToWindow("updateCart", {});
    this.counterUpdate(cartCounter);

    if (!floatingAlert) return;

    const site = window.site;
    const content = site.cart.added;
    this.dispatchToWindow("floatingAlert", { content });
  }

  async remove() {
    const orderToken = this.token;
    const include = "line_items";
    const response = await this.spree.cart.removeItem(
      { orderToken }, this.lineItemIdValue, { include }
    );

    if (response.isFail()) {
      this.handleFailure(response);
      return;
    }

    const cart = response.success();
    const itemTotal = cart.data.attributes.item_total;
    const cartCounter = cart.data.attributes.item_count;

    this.element.remove();
    this.counterUpdate(cartCounter);
    this.dispatchToWindow("updateItemTotal", { itemTotal });
    this.dispatchToWindow("updateLineItem", { cart });
  }

  updateLineItem(event) {
    if (!event.params.variantId) return;
    if (!event.detail.cart) return;

    const cart = event.detail.cart;
    const variantId = event.params.variantId.toString();
    const lineItem = cart.included.find(x => x.type === "line_item" && x.relationships.variant.data.id == variantId);

    if (!lineItem) return;

    this.subtotalValue = lineItem.attributes.subtotal;
    this.totalValue = lineItem.attributes.total;
  }
}
