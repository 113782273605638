import { CartBaseController } from "./cart_base_controller";

/*
 * Populates a country field where users can type to filter and select
 * from a predefined list.
 */
export default class extends CartBaseController {
  static targets = ["id", "iso", "list", "name", "template", "country"];
  static values = {
    validation: String,
  };

  async connect() {
    const countries = await this.countries();

    for (const country of countries) {
      const data = {
        id: country.id,
        iso: country.attributes.iso,
        name: country.attributes.name,
        statesRequired: country.attributes.states_required,
        zipcodeRequired: country.attributes.zipcode_required
      };

      const listItem = this.applyTemplate(data, this.templateTarget);

      this.listTarget.appendChild(listItem);
    }

    this.nameTarget.pattern = countries.map((x) => x.attributes.name).join("|");
    this.nameTarget.disabled = false;
    this.selectCountry();
  }

  selectCountry(event = undefined) {
    const value = this.nameTarget.value.trim();

    if (value === "") return;

    const selectedCountry = this.countryTargets.find((x) => x.value === value);

    if (!selectedCountry) return;

    this.idTarget.value = selectedCountry.dataset.id;
    this.nameTarget.setCustomValidity("");
    this.dispatchToWindow("countryChanged", {...selectedCountry.dataset});
  }

  async countries() {
    const response = await this.spree.countries.list();

    if (!response.success()) return [];

    return response.success().data;
  }
}
