import { CartBaseController } from "./cart_base_controller";

export default class extends CartBaseController {
  static targets = ["id", "list", "name", "template", "state"];
  static values = { validation: String, };

  async render({ detail: { iso, statesRequired } }) {
    const areStatesRequired = statesRequired === "true";

    this.nameTarget.disabled = false;
    this.nameTarget.required = areStatesRequired;
    this.listTarget.innerHTML = "";

    if (!areStatesRequired) return;

    const states = await this.states(iso);

    for (const state of states) {
      const data = { id: state.id, name: state.attributes.name, };
      const listItem = this.applyTemplate(data, this.templateTarget);

      this.listTarget.appendChild(listItem);
    }

    this.nameTarget.pattern = states.map((x) => x.attributes.name).join("|");
  }

  selectState(event) {
    const value = this.nameTarget.value.trim();

    if (!value) return;

    const selectedState = this.stateTargets.find((x) => x.value === value);

    if (!selectedState) return;

    this.idTarget.value = selectedState.dataset.id;
    this.nameTarget.setCustomValidity("");
  }

  async states(countryIso) {
    const response = await this.spree.countries.show(countryIso, {
      include: "states",
    });

    if (response.isFail()) {
      this.handleFailure(response);
      return {};
    }

    const states = response.success().included;

    states.sort((x, y) => x.attributes.name > y.attributes.name);

    return states;
  }
}
