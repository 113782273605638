import { Controller } from "@suttyweb/stimulus";

export class BaseController extends Controller {
  /*
   * Visita una página con soporte para Turbo
   *
   * @param [String] URL
   */
  visit(url) {
    try {
      if (url.startsWith("http")) {
        throw "External";
      } else {
        Turbo.visit(url);
      }
    } catch {
      window.location = url;
    }
  }

  disableForm(formTarget, state) {
    if (!formTarget) return;

    formTarget.elements.forEach((x) => (x.disabled = state));
  }

  setCustomValidity (event) {
    event.target.setCustomValidity(event.params.customValidity);
  }

  unsetCustomValidity (event) {
    event.target.setCustomValidity("");
  }

  dispatchToWindow(name, detail = {}) {
    window.dispatchEvent(new CustomEvent(name, { detail }));
  }

  localizeNumber(string) {
    return parseFloat(string).toLocaleString(this.locale);
  }

  get locale () {
    return document.querySelector("html").lang;
  }

  applyTemplate(data, template = undefined) {
    if (!template) {
      console.log("default template");
      template = this.templateTarget;
    }

    if (!template) {
      console.error("Can't find template", data);
      return;
    }

    const elementContainer = template.content.cloneNode(true);

    for (const key in data) {
      const dasherizedKey = this.dasherize(key);
      const attribute = `data-${dasherizedKey}`;
      const attributeAttribute = `${attribute}-attribute`;
      const contentAttribute = `${attribute}-content`;
      const datasetAttribute = attribute;
      const classAttribute = `${attribute}-class`;

      const attributeSelector = `[${attributeAttribute}]`;
      const contentSelector = `[${contentAttribute}]`;
      const datasetSelector = `[${datasetAttribute}]`;
      const classSelector = `[${classAttribute}]`;

      for (const element of elementContainer.querySelectorAll(attributeSelector)) {
        element.setAttribute(element.dataset[`${key}Attribute`], data[key]);
        element.removeAttribute(attributeAttribute);
      }

      for (const element of elementContainer.querySelectorAll(contentSelector)) {
        element.textContent = data[key];
        element.removeAttribute(contentAttribute);
      }

      for (const element of elementContainer.querySelectorAll(datasetSelector)) {
        element.dataset[key] = data[key];
      }

      for (const element of elementContainer.querySelectorAll(classSelector)) {
        if (data[key]) element.classList.add(data[key]);
        element.removeAttribute(classAttribute);
      }
    }

    return elementContainer;
  }

  // this comes from Stimulus but isn't exported
  camelize(value) {
    return value.replace(/(?:[_-])([a-z0-9])/g, (_, char) => char.toUpperCase());
  }

  // this comes from Stimulus but isn't exported
  dasherize(value) {
    return value.replace(/([A-Z])/g, (_, char) => `-${char.toLowerCase()}`);
  }
}
