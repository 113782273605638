import { CartBaseController } from "./cart_base_controller";

export default class extends CartBaseController {
  static targets = ["paymentMethods", "paymentMethodTemplate", "subtotal", "total", "currency"];
  static values = {
    nextStep: String,
    subtotal: String,
    total: String,
    currency: String,
  };

  currencyValueChanged (currency) {
    for (const currencyTarget of this.currencyTargets) {
      currencyTarget.innerText = currency;
    }
  }

  totalValueChanged (total) {
    const localizedTotal = this.localizeNumber(total);
    const localizeSubtotal = this.localizeNumber(this.subtotalValue);
    const hiddenSubtotal = (total === this.subtotalValue);

    for (const totalTarget of this.totalTargets) {
      totalTarget.innerText = localizedTotal;
    }

    for (const subtotalTarget of this.subtotalTargets) {
      subtotalTarget.innerText = localizeSubtotal;
      subtotalTarget.hidden = hiddenSubtotal;
    }
  }

  async connect () {
    const orderToken = this.token;
    const response = await this.spree.checkout.paymentMethods({ orderToken });

    if (response.isFail()) {
      this.handleFailure(response);
      return;
    }

    const cartResponse = await this.spree.cart.show({ orderToken });

    if (cartResponse.isFail()) {
      this.handleFailure(cartResponse);
      return;
    }

    const cart = cartResponse.success().data;
    const paymentMethods = response.success().data;
    this.paymentMethodsTarget.innerHTML = "";
    this.subtotalValue = cart.attributes.subtotal;
    this.totalValue = cart.attributes.total;
    this.currencyValue = cart.attributes.currency;

    for (const paymentMethod of paymentMethods) {
      const data = {
        id: `payment-method-${paymentMethod.id}`,
        value: paymentMethod.id,
        ...paymentMethod.attributes
      }

      this.paymentMethodsTarget.appendChild(this.applyTemplate(data, this.paymentMethodTemplateTarget));
    }
  }

  async pay(event) {
    event.preventDefault();
    event.stopPropagation();

    const orderToken = this.token;
    const date = new Date;
    const formData = new FormData(event.target);
    const payment_method_id = formData.get("payment_method_id");

    if (!payment_method_id) return;

    this.disableForm(event.target, true);

    // XXX: Currently SpreeClient expects us to send payment source
    // attributes as if it were a credit card.
    let response = await this.spree.checkout.orderUpdate(
      { orderToken },
      {
        order: {
          payments_attributes: [{ payment_method_id }],
        },
        payment_source: {
          [payment_method_id]: {
            name: "Pepitx",
            month: date.getMonth() + 1,
            year: date.getFullYear(),
          },
        },
      }
    );

    if (response.isFail()) {
      this.handleFailure(response);
      this.disableForm(event.target, false);
      return;
    }

    response = await this.spree.checkout.complete({ orderToken });

    if (response.isFail()) {
      this.handleFailure(response);
      this.disableForm(event.target, false);
      return;
    }

    let checkoutUrl = this.nextStepValue;
    const checkoutUrls = await this.spree.sutty.getCheckoutURL({ orderToken });

    if (checkoutUrls.data.length > 0) checkoutUrl = checkoutUrls.data[0];

    this.visit(checkoutUrl);
  }
}
