import { CartBaseController } from "./cart_base_controller";

export default class extends CartBaseController {
  static targets = ["email", "rates", "rateTemplate", "shippingMethodId", "specialInstructions"];
  static values = {
    nextStep: String
  };

  async rates (event = undefined) {
    event.preventDefault();

    const formData = new FormData(event.target);
    const orderToken = this.token;
    const email = this.emailTarget.value;
    const ship_address_attributes = Object.fromEntries(formData);
    const bill_address_attributes = ship_address_attributes;
    const special_instructions = this.specialInstructionsTarget.value.trim();

    this.disableForm(event.target, true);

    const response = await this.spree.checkout.orderUpdate(
      { orderToken },
      { order: { email, special_instructions, ship_address_attributes, bill_address_attributes } }
    );

    if (response.isFail()) {
      this.handleFailure(response);
      this.disableForm(event.target, false);
      return;
    }

    const shippingMethods = await this.shippingMethods(orderToken);

    if (!shippingMethods) {
      this.disableForm(event.target, false);
      return;
    }

    const shippingMethod = shippingMethods.data[0];
    const shippingRates = shippingMethods.included.filter(
      (x) => x.type == "shipping_rate"
    );

    this.shippingMethodIdTarget.value = shippingMethod.id;
    this.ratesTarget.innerHTML = "";

    for (const shippingRate of shippingRates) {
      const finalPrice = parseFloat(shippingRate.attributes.final_price);
      const cost = parseFloat(shippingRate.attributes.cost);

      const data = {
        selected: shippingRate.attributes.selected,
        id: shippingRate.id,
        input: `shipping-rate-${shippingRate.id}`,
        name: shippingRate.attributes.name,
        cost: this.localizeNumber(cost),
        finalPrice: this.localizeNumber(finalPrice),
        promotionApplied: (cost > finalPrice) ? "red" : "d-none",
        currency: response.success().data.attributes.currency
      };

      this.ratesTarget.appendChild(this.applyTemplate(data, this.rateTemplateTarget));
    }

    this.disableForm(event.target, false);
  }

  async payment (event = undefined) {
    event.preventDefault();
    let response;

    const formData = new FormData(event.target);
    const orderToken = this.token;
    const data = Object.fromEntries(formData);

    if (!data.selected_shipping_rate_id) return;

    this.disableForm(event.target, true);

    response = await window.spree.cart.show({ orderToken });

    if (response.success().data.attributes.state == "cart") {
      response = await window.spree.checkout.orderNext({ orderToken });
    }

    response = await window.spree.checkout.orderUpdate(
      { orderToken },
      { order: { shipments_attributes: [{ ...data }] } }
    );

    if (response.isFail()) {
      this.handleFailure(response);
      return;
    }

    if (response.success().data.attributes.state == "delivery") {
      response = await window.spree.checkout.orderNext({ orderToken });
    }

    if (response.isFail()) {
      this.handleFailure(response);
      return;
    }

    Turbo.visit(this.nextStepValue);

    this.disableForm(event.target, false);
  }

  async shippingMethods(orderToken) {
    const shippingMethods = await this.spree.checkout.shippingMethods(
      { orderToken },
      { include: "shipping_rates" }
    );

    if (shippingMethods.isFail()) {
      this.handleFailure(shippingMethods);
      return;
    }

    return shippingMethods.success();
  }
}
