import { BaseController } from "./base_controller";

export default class extends BaseController {
  static targets = ["area", "alertTemplate"];

  show({ detail: { template, type, messages } }) {
    const templateTarget = this[`${template}TemplateTarget`];
    const data = {
      type: `alert-${type}`,
      messages
    }

    if (!templateTarget) {
      console.error("No se encuentra la plantilla", template, type, messages);
      return;
    }

    this.areaTarget.innerHTML = "";
    this.areaTarget.appendChild(this.applyTemplate(data, templateTarget));

    this.element.classList.add("o-5");
    this.element.classList.remove("o-0");
  }

  hide() {
    this.element.classList.add("o-0");
    this.element.classList.remove("o-5");
  }
}
