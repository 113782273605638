import { BaseController } from "./base_controller";

/*
 * Base controller, shared methods go here and other classes extend from
 * this.
 */
export class CartBaseController extends BaseController {
  get spree() {
    return window.spree;
  }

  /*
   * The storage
   */
  get storage() {
    return window.localStorage;
  }

  /*
   * Temporary storage
   */
  get storageTemp() {
    return window.sessionStorage;
  }

  /*
   * Gets the order token and creates a cart if it doesn't exist.
   *
   * @return [String]
   */
  async tokenGetOrCreate() {
    return this.token || (await this.cartCreate());
  }

  /*
   * Creates an order and stores the data into localStorage.
   *
   * @return [String]
   */
  async cartCreate() {
    const response = await this.spree.cart.create();

    // If we fail here it's probably a server error, so we inform the
    // user.
    if (response.isFail()) {
      this.handleFailure(response);
      return;
    }

    this.storage.setItem("token", response.success().data.attributes.token);

    return this.token;
  }


  /*
   * The orderToken
   *
   * @return [String]
   */
  get token() {
    return this.storage.getItem("token");
  }

  /*
   * The bearerToken
   *
   * @return [String]
   */
  get bearerToken() {
    return this.storageTemp.getItem("bearerToken");
  }

  set bearerToken(token) {
    this.storageTemp.setItem("bearerToken", token);
  }

  /*
   * Updates the item counter
   */
  counterUpdate(cartCounter) {
    this.dispatchToWindow("counterUpdate", { cartCounter });
    this.storage.setItem("cartCounter", cartCounter);
  }

  async handleFailure(response) {
    const detail = { type: "primary", template: "alert" };
    const site = window.site;
    const fail = response.fail();

    switch (fail.name) {
      case "MisconfigurationError":
        detail.messages = fail.message;
        break;
      case "NoResponseError":
        detail.messages = site.i18n.alerts.no_response_error;
        break;
      case "SpreeError":
        detail.messages = site.i18n.alerts.spree_error;
        break;
      case "BasicSpreeError":
        // XXX: The order is missing, we need to start a new one
        if (fail.serverResponse.status === 404) {
          detail.messages = site.i18n.alerts.recover_order;
        } else {
          detail.messages = response.fail().summary;
        }

        break;
      case "ExpandedSpreeError":
        // XXX: La API devuelve una combinación de errores.
        detail.messages = this.combineErrors(fail.errors).join(". ");

        break;
      default:
        detail.messages = fail.message;
    }

    this.dispatchToWindow("notification", detail);
    console.error(fail.name, detail);
  }

  combineErrors(errorsObject) {
    const combinedErrors = [];

    for (const key in errorsObject) {
      const value = errorsObject[key];
      let translatedKey = window.site.i18n.errors[key];

      if (!translatedKey) {
        console.error("No hay traducción", key);
        translatedKey = key;
      }

      switch (value.constructor.name) {
        case "Object":
          for (const subValue of this.combineErrors(value)) {
            combinedErrors.push(`${translatedKey} ${subValue}`);
          }
          break;

        case "String":
          combinedErrors.push(`${translatedKey} ${value}`);
          break;

        case "Array":
          for (const item of value) {
            combinedErrors.push(`${translatedKey} ${item}`);
          }
          break;
      }
    }

    return combinedErrors.flat();
  }
}
