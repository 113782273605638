import { CartBaseController } from "./cart_base_controller";

export default class extends CartBaseController {
  static targets = ["order"];

  async connect() {
    if (this.clear) {
      this.storage.clear();
      window.dispatchEvent(
        new CustomEvent("cart:counter", { detail: { item_count: 0 } })
      );
    }

    if (!this.template) return;

    if (this.storage.cart) {
      const order = this.cart.data.attributes;
      const products = this.products;
      const site = window.site;
      const shipping_address = JSON.parse(
        this.storage.getItem("shipping_address")
      );

      const data = { order, products, site, shipping_address };

      this.storage.setItem("confirmation", JSON.stringify(data));
    } else {
      data = JSON.parse(this.storage.getItem("confirmation"));
    }

    this.render(data);
  }

  render(data = {}) {
    this.engine
      .parseAndRender(this.template, data)
      .then((html) => (this.orderTarget.innerHTML = html));
  }

  get clear() {
    return this.element.dataset.clear;
  }

  get template() {
    return window.templates[this.element.dataset.template];
  }
}
