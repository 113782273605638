import { CartBaseController } from "./cart_base_controller";

/*
 * Renders the order table.  All products are stored on localStorage, so
 * we just fetch that information and render the cart contents using
 * Liquid.
 */
export default class extends CartBaseController {
  static targets = ["cart", "currency", "itemTotal", "itemCount", "itemTemplate"];
  static values = {
    itemTotal: String,
    currency: String
  };

  connect() {
    this.render();
  }

  async render(event = undefined) {
    const orderToken = await this.tokenGetOrCreate();
    const include = "line_items";
    const response = await this.spree.cart.show({ orderToken }, { include });

    if (response.isFail()) {
      this.handleFailure(response);
      return;
    }

    this.cartTarget.innerHTML = "";

    const cart = response.success();

    for (const line_item of cart.included) {
      const lineItem = line_item.attributes;

      const data = {
        cartVariantIdParam: line_item.relationships.variant.data.id,
        cartVariantIdValue: line_item.relationships.variant.data.id,
        cartLineItemIdValue: line_item.id,
        cartTotalValue: lineItem.total,
        cartSubtotalValue: lineItem.subtotal,
        cartTitle: lineItem.name,
        cartImage: lineItem.extra_attributes.thumbnail_url || lineItem.extra_attributes.image_url,
        stock: lineItem.total_on_hand,
        price: this.localizeNumber(lineItem.price),
        currency: lineItem.currency,
        quantity: lineItem.quantity,
      };

      this.cartTarget.appendChild(this.applyTemplate(data, this.itemTemplateTarget));
    }

    this.itemTotalValue = cart.data.attributes.item_total;
    this.currencyValue = cart.data.attributes.currency;
  }

  itemTotalValueChanged (value, previousValue) {
    this.itemTotalTarget.innerHTML = this.localizeNumber(value);
  }

  currencyValueChanged (value, previousValue) {
    this.currencyTarget.innerHTML = value;
  }

  updateItemTotal({ detail: { itemTotal } }) {
    this.itemTotalValue = itemTotal;
  }

  /*
   * Renderiza el carrito frente a cualquier evento de almacenamiento en
   * otras pestañas.  counterUpdate() lanza este evento.  El carrito
   * siempre está actualizado con lo que se hizo en otras pestañas
   * gracias a esto.
   */
  renderFromStorage() {
    this.render();
  }
}
