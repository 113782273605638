import { BaseController } from "./base_controller";

/*
 * Mantiene el stock actualizado, consultando a la API.
 *
 * * Obtiene todas las variantes en el controlador
 * * Consulta a la API
 * * Actualiza stock y precio
 * * Deshabilita botón si no está en stock
 */
export default class extends BaseController {
  static targets = ["product"];
  static values = {
    perPage: {
      type: Number,
      default: 100
    }
  };

  connect() {
    this.updateStock();
  }

  async updateStock() {
    const allSkus = this.skus;

    if (allSkus.length === 0) return;

    // El paginado es para prevenir que la petición se haga muy grande y
    // falle entera.
    const pages = Math.ceil(allSkus.length / this.perPageValue);

    let start = 0;
    let end = this.perPageValue;

    for (let localPage = 1; localPage <= pages; localPage++) {
      const skus = allSkus.slice(start, end).join(",");

      start = this.perPageValue * localPage;
      end = start + this.perPageValue;

      const filter = { skus };
      let response = await window.spree.products.list({ filter });

      if (response.isFail()) {
        console.error(response.fail());
        return;
      }

      this.updateLocalProducts(response.success().data);

      // Recorrer todas las páginas
      // XXX: Podríamos usar next pero la página 1 siempre se devuelve a
      // sí misma y entraríamos en un loop infinito.
      for (let page = 2; page <= response.success().meta.total_pages; page++) {
        response = await window.spree.products.list({ filter, page });

        if (response.isFail()) {
          console.error(response.fail());
          continue;
        }

        this.updateLocalProducts(response.success().data);
      }
    }
  }

  /*
   * La lista de todas las variantes incluidas en el controlador que no
   * estén vacías.  Usamos los SKUs porque no tenemos forma de filtrar
   * por ID.
   *
   * @return [Array]
   */
  get skus() {
    return [
      ...new Set(
        this.productTargets
          .map((p) => p.dataset.sku)
          .filter((x) => x.length > 0)
      ),
    ];
  }

  /*
   * Los productos pueden estar duplicados así que buscamos todos.
   */
  updateLocalProducts(products) {
    for (const local of this.productTargets) {
      for (const product of products.filter(
        (p) =>
          local.dataset.cartVariantIdValue ===
          p.relationships.default_variant.data.id
      )) {
        local.dataset.cartInStock = product.attributes.in_stock;
        local.dataset.cartPrice = product.attributes.price;

        local
          .querySelectorAll("[data-stock-add]")
          .forEach(
            (button) => (button.disabled = !product.attributes.in_stock)
          );
        local
          .querySelectorAll("[data-stock-price]")
          .forEach(
            (price) => (price.innerText = this.localizeNumber(product.attributes.price))
          );
        local
          .querySelectorAll("[data-stock-currency]")
          .forEach(
            (currency) => (currency.innerText = product.attributes.currency)
          );
        local
          .querySelectorAll("[data-total-on-hand]")
          .forEach(
            (element) => (element.setAttribute(element.dataset.totalOnHand, product.attributes.total_on_hand))
          );
      }
    }
  }
}
