import { Controller } from "@suttyweb/stimulus";

export default class extends Controller {
  static targets = ["counter"];

  connect() {
    if (this.hasCounterTarget) return;

    console.error("Missing counter target");
  }

  counterTargetConnected (counter) {
    this.updateFromStorage();
  }

  updateFromStorage (event = undefined) {
    const cartCounter = window.localStorage.getItem("cartCounter");

    if (cartCounter) this.counterTarget.innerText = cartCounter;
  }

  update({ detail: { cartCounter } }) {
    this.counterTarget.innerText = cartCounter;
  }
}
